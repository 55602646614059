import React, { useState, useEffect } from "react";
import { FaTrash, FaUndo } from "react-icons/fa";
import { PlusIcon } from "@heroicons/react/24/solid";
import ConfirmationModal from "../ConfirmationModal2";
import { useData } from "../../context/FormationDataContext";
import AddNewFormationEntrySlideOver from "./AddNewFormationEntrySlideOver";
import { useLocation } from "../../context/LocationContext";
import { useImageViewer } from "../../context/ImageViewerContext";
import { Tooltip as ReactTooltip } from "react-tooltip";

const TableHeader = () => (
  <tr>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Formation Type
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Formation Start Md
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Formation Start Tvd
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Formation End Tvd
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Formation End Md
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Formation Description
    </th>
  </tr>
);

const FormationData = () => {
  const {
    data,
    updateData,
    handleSaveFromImageViewer,
    handleDelete,
    handleRestore,
    handleAddNewEntry,
  } = useData();
  const {
    setSelectedFieldData,
    setSelectedFieldReference,
    setSelectedReference,
    setIsModalOpen,
    setTempFieldValue,
  } = useLocation();

  const { setIsSlideOverOpen } = useImageViewer();

  const [isAddNewEntrySlideOverOpen, setIsAddNewEntrySlideOverOpen] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [localAcceptedValues, setLocalAcceptedValues] = useState([]);
  const [localDeletedValues, setLocalDeletedValues] = useState([]);
  const [selectedField, setSelectedField] = useState(null); // Store selected field data

  useEffect(() => {
    if(data) {
      setLocalAcceptedValues(Object.keys(data.acceptedValues || {}));
      setLocalDeletedValues(Object.keys(data.deletedValues || {}));
    }
  }, [data]);

  const handleFieldClick = (fieldData, reference, displayValue) => {
    setSelectedFieldData(fieldData);
    setSelectedFieldReference(reference);
    setSelectedReference(displayValue);
    setSelectedField(fieldData);
    setIsSlideOverOpen(true);
  };

  const renderTableRows = (values, isDeleted) => {
    if (!values) return null;
    return values.map((value) => {
      const fieldData =
        data.acceptedValues[value] || data.deletedValues[value];
      if (!fieldData) return null;

      return (
        <tr key={value}>
          <td
            className="px-4 py-2"
            onClick={() => handleFieldClick(fieldData, "Formation Type", value)}
          >
            {value}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              handleFieldClick(
                fieldData.formation_formation_start_md,
                "Formation Start Md",
                fieldData.formation_formation_start_md?.display?.value
              )
            }
          >
            {fieldData.formation_formation_start_md?.display?.value || "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              handleFieldClick(
                fieldData.formation_formation_start_tvd,
                "Formation Start Tvd",
                fieldData.formation_formation_start_tvd?.display?.value
              )
            }
          >
            {fieldData.formation_formation_start_tvd?.display?.value || "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              handleFieldClick(
                fieldData.formation_formation_end_tvd,
                "Formation End Tvd",
                fieldData.formation_formation_end_tvd?.display?.value
              )
            }
          >
            {fieldData.formation_formation_end_tvd?.display?.value || "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              handleFieldClick(
                fieldData.formation_formation_end_md,
                "Formation End Md",
                fieldData.formation_formation_end_md?.display?.value
              )
            }
          >
            {fieldData.formation_formation_end_md?.display?.value || "N/A"}
          </td>
          <td
            className="p-4 text-center flex flex-row justify-between items-center whitespace-pre-wrap break-words"
            onClick={() =>
              handleFieldClick(
                fieldData.formation_description,
                "Formation Description",
                fieldData.formation_description?.display?.value
              )
            }
          >
            <span className="flex-grow text-left">
              {fieldData.formation_description?.display?.value || "N/A"}
            </span>
            <div className="ml-2">
              {!isDeleted && (
                <>
                  <button
                    data-tooltip-id="trashTooltip"
                    data-tooltip-content="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(value);
                    }}
                    className="text-red-500"
                  >
                    <FaTrash />
                  </button>
                  <ReactTooltip id="trashTooltip" />
                </>
              )}
              {isDeleted && (
                <>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRestore(value);
                    }}
                    className="text-green-500"
                    data-tooltip-id="restoreTooltip"
                    data-tooltip-content="Restore"
                  >
                    <FaUndo />
                  </button>
                  <ReactTooltip id="restoreTooltip" />
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col justify-between">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-bold mb-4">Accepted Values</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(localAcceptedValues, false)}
          </tbody>
        </table>
        <>
          <button
            data-tooltip-id="plusToolTip"
            data-tooltip-content="Add New Data"
            className="bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center mt-2"
            onClick={() => setIsAddNewEntrySlideOverOpen(true)}
          >
            <PlusIcon className="w-4 h-4" />
          </button>
          <ReactTooltip id="plusToolTip" />
        </>
      </div>

      <div className="overflow-x-auto my-20">
        <h2 className="text-lg font-bold mb-4 text-gray-400">Deleted Values</h2>
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(localDeletedValues, true)}
          </tbody>
        </table>
      </div>

      <AddNewFormationEntrySlideOver
        isOpen={isAddNewEntrySlideOverOpen}
        onClose={() => setIsAddNewEntrySlideOverOpen(false)}
        onSubmit={handleAddNewEntry}
        data={data}
      />
    </div>
  );
};

export default FormationData;
