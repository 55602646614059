import React, { createContext, useState, useEffect, useContext, useMemo } from "react";

const ComplicationDataContext = createContext();

export const ComplicationDataProvider = ({ children }) => {
  // const [data, setData] = useState({
  //   acceptedValues: {
  //     22: {
  //       complication_type: {
  //         options: {
  //           Clays: {
  //             count: 1,
  //             reference: 83,
  //           },
  //         },
  //         history: [],
  //         display: {
  //           value: "Clays",
  //           reference: 83,
  //           user_id: "user132",
  //         },
  //       },
  //       complication_description: {
  //         options: {
  //           "Clays observed at shakers from +/- 22 m. Trickled AMC Det Xtra from 28 m. Pumped KCI sweeps during drilling of sticky clays. No mud rings.":
  //             {
  //               count: 1,
  //               length: 138,
  //               rank: 2,
  //               reference: 83,
  //             },
  //         },
  //         history: [],
  //         display: {
  //           value:
  //             "Clays observed at shakers from +/- 22 m. Trickled AMC Det Xtra from 28 m. Pumped KCI sweeps during drilling of sticky clays. No mud rings.",
  //           reference: 83,
  //           user_id: "user132",
  //         },
  //       },
  //       complication_problem_resolution: {
  //         options: {
  //           "Resolution details": {
  //             count: 1,
  //             reference: 84,
  //           },
  //         },
  //         history: [],
  //         display: {
  //           value: "Resolution details",
  //           reference: 84,
  //           user_id: "user132",
  //         },
  //       },
  //       complication_time_taken: {
  //         options: {
  //           "2 hours": {
  //             count: 1,
  //             reference: 85,
  //           },
  //         },
  //         history: [],
  //         display: {
  //           value: "2 hours",
  //           reference: 85,
  //           user_id: "user132",
  //         },
  //       },
  //     },
  //   },
  //   deletedValues: {
  //     408: {
  //       complication_type: {
  //         options: {
  //           "tight pull": {
  //             count: 1,
  //             reference: 38,
  //           },
  //         },
  //         history: [],
  //         display: {
  //           value: "tight pull",
  //           reference: 38,
  //           user_id: "user132",
  //         },
  //       },
  //       complication_description: {
  //         options: {
  //           "Tight pull observed during drilling": {
  //             count: 1,
  //             length: 40,
  //             rank: 1,
  //             reference: 39,
  //           },
  //         },
  //         history: [],
  //         display: {
  //           value: "Tight pull observed during drilling",
  //           reference: 39,
  //           user_id: "user132",
  //         },
  //       },
  //       complication_problem_resolution: {
  //         options: {
  //           "Applied torque to release": {
  //             count: 1,
  //             reference: 40,
  //           },
  //         },
  //         history: [],
  //         display: {
  //           value: "Applied torque to release",
  //           reference: 40,
  //           user_id: "user132",
  //         },
  //       },
  //       complication_time_taken: {
  //         options: {
  //           "1 hour": {
  //             count: 1,
  //             reference: 41,
  //           },
  //         },
  //         history: [],
  //         display: {
  //           value: "1 hour",
  //           reference: 41,
  //           user_id: "user132",
  //         },
  //       },
  //       message: {
  //         user_id: "user132",
  //         value: "deleted by user132",
  //       },
  //     },
  //   },
  // });

  // const updateData = (newData) => {
  //   setData(newData);
  // };



  const [data, setData] = useState(null);
  const [wellId, setWellId] = useState("");

  const getWellIdFromQueryString = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('well_id');
  };


  useEffect(() => {
    const well_id = getWellIdFromQueryString();
    if (well_id) {
      setWellId(well_id);
    }
  }, [window.location.search]);

  useEffect(() => {
    if(wellId) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/v1/well/get-complication-data/${wellId}`,{
            method: "GET",
            credentials: 'include',
          });
          if(response) {
            const data = await response.json();
            console.log("data:",data);
            if(data && data.data) {
              setData(data.data);
            }
          }
        } catch (err) {
          console.log("Err while fetching",err);
        }
      };
      fetchData();
    } 
  }, [wellId]);



const updateData = async (newData) => {
  try {
    const response = await fetch(`/v1/well/update-complication-data`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        well_id: wellId,
        complication_data: newData
      }),
      credentials: 'include'
    });

    if (response.ok) {
      const data = await response.json();
      if (data && data.data) {
        console.log("Complication data successfully update:",data.data);
        setData(newData);
      }
    } else {
      console.error('Failed to update complication data', response.statusText);
    }
  } catch (err) {
    console.error('Error updating complication data', err);
  }
};


  const handleAddNewEntry = (formData) => {
    const newEntry = {
      [formData.Name]: {
        complication_type: {
          options: {
            [formData["Complication Type"]]: {
              count: 1,
              reference: formData["Complication Type_ref"],
            },
          },
          history: [],
          display: {
            value: formData["Complication Type"],
            reference: formData["Complication Type_ref"],
            user_id: "user132",
          },
        },
        complication_description: {
          options: {
            [formData.Description]: {
              count: 1,
              length: formData.Description.length,
              reference: formData.Description_ref,
            },
          },
          history: [],
          display: {
            value: formData.Description,
            reference: formData.Description_ref,
            user_id: "user132",
          },
        },
        complication_problem_resolution: {
          options: {
            [formData.ProblemResolution]: {
              count: 1,
              reference: formData.ProblemResolution_ref,
            },
          },
          history: [],
          display: {
            value: formData.ProblemResolution,
            reference: formData.ProblemResolution_ref,
            user_id: "user132",
          },
        },
        complication_time_taken: {
          options: {
            [formData.TimeTaken]: {
              count: 1,
              reference: formData.TimeTaken_ref,
            },
          },
          history: [],
          display: {
            value: formData.TimeTaken,
            reference: formData.TimeTaken_ref,
            user_id: "user132",
          },
        },
      },
    };

    const updatedData = {
      ...data,
      acceptedValues: {
        ...data.acceptedValues,
        ...newEntry,
      },
    };

    updateData(updatedData);
  };

  const handleDelete = (value) => {
    const updatedAcceptedValues = { ...data.acceptedValues };
    const deletedValue = updatedAcceptedValues[value];
    delete updatedAcceptedValues[value];

    const updatedDeletedValues = {
      ...data.deletedValues,
      [value]: deletedValue,
    };

    updatedDeletedValues[value].message = {
      user_id: "user132",
      value: "deleted by user132",
    };

    updateData({
      ...data,
      acceptedValues: updatedAcceptedValues,
      deletedValues: updatedDeletedValues,
    });
  };

  const handleRestore = (value) => {
    const updatedDeletedValues = { ...data.deletedValues };
    const restoredValue = updatedDeletedValues[value];
    delete updatedDeletedValues[value];

    const updatedAcceptedValues = {
      ...data.acceptedValues,
      [value]: restoredValue,
    };

    updateData({
      ...data,
      acceptedValues: updatedAcceptedValues,
      deletedValues: updatedDeletedValues,
    });
  };

  const value = useMemo(
    () => ({
      data,
      updateData,
      handleDelete,
      handleRestore,
      handleAddNewEntry,
    }),
    [data]
  );

  return (
    <ComplicationDataContext.Provider value={value}>
      {children}
    </ComplicationDataContext.Provider>
  );
};

// Custom hook to use the ComplicationDataContext
export const useComplicationData = () => {
  return useContext(ComplicationDataContext);
};
