import React, { useEffect } from "react";
import { useLocation } from "../../context/LocationContext";
import { useImageViewer } from "../../context/ImageViewerContext";

const WellLocationData = () => {
  const {
    well,
    locationData,
    setSelectedFieldReference,
    setSelectedFieldData,
    setIsSlideOverOpen,
    setSelectedReference,
    setIsModalOpen,
    setTempFieldValue,
    updateWellLocation
  } = useLocation();

  const { setIsSlideOverOpen: openImageViewer, tempFieldValue,tempReference, newData } = useImageViewer();

  const handleFieldClick = (field, reference) => {
    setSelectedFieldReference(field);
    setSelectedFieldData(locationData[field]);
    setSelectedReference(reference);
    openImageViewer(true);
  };

  const handleOptionClick = (key) => {
    setTempFieldValue(key);
    setIsModalOpen(true);
  };

  useEffect(()=>{
    handleSave(tempFieldValue,tempReference);
  },[newData])
  
  const handleSave = (newValue, newReference) => {
    setTempFieldValue(newValue);
    setSelectedReference(newReference);
    updateWellLocation({
      newValue,
      newReference
    });
    setIsModalOpen(true);
  };

  return (
    <div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border">Field</th>
            <th className="py-2 px-4 border">Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(locationData).map(([field, data]) => (
            <tr key={field} onClick={() => handleFieldClick(field, data.display.reference)}>
              <td className="py-2 px-4 border cursor-pointer text-black">{field}</td>
              <td className="py-2 px-4 border cursor-pointer text-blue-500">{data.display.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WellLocationData;
