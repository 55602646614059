// src/context/LocationContext.js
import React, { createContext, useState, useEffect, useContext } from "react";

const LocationContext = createContext();

export const useLocation = () => useContext(LocationContext);

export const LocationProvider = ({ children }) => {
  // const [locationData, setLocationData] = useState({
  //   well_name: {
  //     options: {
  //       1: { count: 1, reference: 6 },
  //       "EDITHBURGH 1": { count: 1, reference: 5 },
  //     },
  //     history: [],
  //     display: { value: "Edithburg", reference: 4, user_id: "user124" },
  //   },
  //   well_site_name: {
  //     options: {
  //       1: { count: 1, reference: 6 },
  //       "Site Charlie": { count: 1, reference: 5 },
  //     },
  //     history: [],
  //     display: { value: "site 4", reference: 4, user_id: "user125" },
  //   },
  //   field_name: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       "Field Delta": { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "Field 4", reference: 4, user_id: "user126" },
  //   },
  //   block_name: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       "Block Echo": { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "Block 6", reference: 4, user_id: "user127" },
  //   },
  //   country_name: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       "Country Foxtrot": { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "Country 2", reference: 4, user_id: "user128" },
  //   },
  //   uwi_well: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       UWI123456: { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "UWI123456", reference: 4, user_id: "user129" },
  //   },
  //   uwi_bore: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       UWI654321: { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "UWI654321", reference: 4, user_id: "user130" },
  //   },
  //   ns_latitude: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       "10 deg 20' 30\" N": { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "10 deg 20' 30\" N", reference: 4, user_id: "user131" },
  //   },
  //   ew_longitude: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       "20 deg 40' 50\" E": { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "20 deg 40' 50\" E", reference: 4, user_id: "user132" },
  //   },
  //   well_type: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       Producer: { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "Producer", reference: 4, user_id: "user133" },
  //   },
  //   depth_reference: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       MSL: { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "MSL", reference: 4, user_id: "user134" },
  //   },
  //   rt_elevation: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       "15m": { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "15m", reference: 4, user_id: "user135" },
  //   },
  //   target_reservoir: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       "Reservoir Golf": { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "xyz", reference: 4, user_id: "user136" },
  //   },
  //   total_depth: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       "1200m": { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "1200m", reference: 4, user_id: "user137" },
  //   },
  //   bottom_hole_temperature: {
  //     options: {
  //       1: { count: 1, reference: 67 },
  //       "150 deg C": { count: 1, reference: 4 },
  //     },
  //     history: [],
  //     display: { value: "150 deg C", reference: 4, user_id: "user138" },
  //   },
  // });

  const [locationData, setLocationData] = useState({});
  const [selectedSection, setSelectedSection] = useState("Well location data");
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedReference, setSelectedReference] = useState(null);
  const [selectedFieldData, setSelectedFieldData] = useState(null);
  const [selectedFieldReference, setSelectedFieldReference] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempFieldValue, setTempFieldValue] = useState(null);
  const [tempReference, setTempReference] = useState(null);
  const [well, setWell] = useState({ well_name : "", well_id : ""});
  const [wellId, setWellId] = useState("");
  const [wellLocData, updateWellLocation] = useState("");
  const [pdfs, setPdfs] = useState([]);




  useEffect(() => {
    if (well.well_id) {
      const queryString = `?well_id=${encodeURIComponent(well.well_id)}`;
      window.history.replaceState(null, '', queryString);
    }
  }, [well.well_id]);

  useEffect(() => {
    if(well.well_id) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/v1/well/get-well-location-data/${well.well_id}`,{
            method: "GET",
            credentials: 'include',
          });
          if(response) {
            const data = await response.json();
            if(data && data.data && data.data.wellLocation) {
              setLocationData(data.data.wellLocation);
              setPdfs(data.data.pdfs);
              setWellId(data.data.wellId);
            }
          }
        } catch (err) {
          // handle no data found case
        }
      };
      fetchData();
    }
  }, [well]);


  
  useEffect(() => {
    console.log("update well location:",wellLocData);
    if(wellLocData && wellLocData.newValue) {
      setLocationData({
        ...locationData,
        [selectedFieldReference] : {
          ...locationData[selectedFieldReference],
          display : {
            ...locationData[selectedFieldReference]['display'],
            'value' : wellLocData.newValue,
            'reference' : wellLocData.newReference
          }
        }
      }); 
    
      const updateData = async () => {
        try {
          console.log("Using wellID", wellId);
          const response = await fetch(`/v1/well/update-well-location-data`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              well_id: wellId,
              well_location_data: {
                [selectedFieldReference] : {
                  ...locationData[selectedFieldReference],
                  display : {
                    ...locationData[selectedFieldReference]['display'],
                    'value' : wellLocData.newValue,
                    'reference' : wellLocData.newReference
                  }
                }
              }
            }),
            credentials: 'include'
          });
      
          if (response.ok) {
            const data = await response.json();
            if (data && data.data) {
              console.log(data.data);
              // setLocationData(data.data);
            }
          } else {
            console.error('Failed to update well location data', response.statusText);
          }
        } catch (err) {
          console.error('Error updating well location data', err);
        }
      };
      updateData();
    }
      
  }, [wellLocData]);



  const getWellIdFromQueryString = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('well_id');
  };


  useEffect(() => {
    const well_id = getWellIdFromQueryString();
    if (well_id) {
      setWell({...well,well_id});
    }
  }, [window.location.search]);


  useEffect(() => {
    const well_id = getWellIdFromQueryString();
    if (well_id) {
      setWellId(well_id);
    }
  }, [window.location.search]);

  
  
  return (
    <LocationContext.Provider
      value={{
        well,
        setWell,
        locationData,
        setLocationData,
        selectedSection,
        setSelectedSection,
        isSlideOverOpen,
        setIsSlideOverOpen,
        selectedReference,
        setSelectedReference,
        selectedFieldData,
        setSelectedFieldData,
        selectedFieldReference,
        setSelectedFieldReference,
        isModalOpen,
        setIsModalOpen,
        tempFieldValue,
        setTempFieldValue,
        tempReference,
        setTempReference,
        updateWellLocation,
        pdfs
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
