import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Sidebar({
  isSidebarOpen,
  toggleSidebar,
  selectedCountry,
  setSelectedCountry,
  selectedBlock,
  setSelectedBlock,
  selectedField,
  setSelectedField,
  selectedSite,
  setSelectedSite,
  selectedWell,
  setSelectedWell,
  activeMenu,
  setActiveMenu,
  handleButtonClick,
}) {
  const navigate = useNavigate();
  const companyName = "Company A"; // Assuming single company

  const [data, setData] = useState({
    countries: [],
    blocks: {},
    fields: {},
    sites: {},
    wells: {},
  });

  useEffect(() => {
    fetch('/v1/well/get-wells-data-filter', {
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.data) {
          setData(data.data);
        }
      })
      .catch(error => {
        console.error('Error fetching suggestions:', error);
      });
  }, []);

  const toggleSubMenu = (menu) => {
    setActiveMenu(activeMenu === menu ? "" : menu);
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setSelectedBlock("");
    setSelectedField("");
    setSelectedSite("");
    setSelectedWell({ well_id : "", well_name : ""});
  };

  const handleBlockChange = (block) => {
    setSelectedBlock(block);
    setSelectedField("");
    setSelectedSite("");
    setSelectedWell({ well_id : "", well_name : ""});
  };

  const handleFieldChange = (field) => {
    setSelectedField(field);
    setSelectedSite("");
    setSelectedWell({ well_id : "", well_name : ""});
  };

  const handleSiteChange = (site) => {
    setSelectedSite(site);
    setSelectedWell({ well_id : "", well_name : ""});
  };

  const handleCreateWell = () => {
    navigate("/create-well", {
      state: {
        country: selectedCountry,
        block: selectedBlock,
        field: selectedField,
        site: selectedSite,
      },
    });
  };

  return (
    <aside
      className={`bg-indigo-950 text-white ${
        isSidebarOpen ? "w-64" : "w-20"
      } transition-all duration-300 flex flex-col`}
    >
      <div className={`p-4 ${!isSidebarOpen ? "absolute top-1/4 left-1" : ""}`}>
        <button onClick={toggleSidebar} className="focus:outline-none">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={
                isSidebarOpen
                  ? "M6 18L18 6M6 6l12 12"
                  : "M4 6h16M4 12h16m-7 6h7"
              }
            />
          </svg>
        </button>
      </div>
      {isSidebarOpen && (
        <nav className="mt-4 flex-grow">
          <button
            onClick={() => navigate("/create-well")}
            className="hover:bg-gray-300 text-white p-2 rounded border border-white text-center mb-5 ml-3 hover:text-black"
          >
            Create Well
          </button>
          <ul className="bg-indigo-900">
            <li className="flex items-center">
              <a
                href="#"
                className="block p-4 flex items-center bg-green-600 w-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 mr-2"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.792 2.938A49.069 49.069 0 0 1 12 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 0 1 1.541 1.836v1.044a3 3 0 0 1-.879 2.121l-6.182 6.182a1.5 1.5 0 0 0-.439 1.061v2.927a3 3 0 0 1-1.658 2.684l-1.757.878A.75.75 0 0 1 9.75 21v-5.818a1.5 1.5 0 0 0-.44-1.06L3.13 7.938a3 3 0 0 1-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836Z"
                    clipRule="evenodd"
                  />
                </svg>
                Data Filter
              </a>
            </li>
            <li className="">
              <div className="block p-4 flex items-center justify-between">
                <span className="flex items-center">{companyName}</span>
              </div>
              <div
                onClick={() => toggleSubMenu("Country")}
                className="block p-4 hover:bg-indigo-950 flex items-center justify-between cursor-pointer"
              >
                <span className="flex items-center">
                  Country {selectedCountry && `(${selectedCountry})`}
                </span>
                <svg
                  className={`w-4 h-4 transition-transform transform ${
                    activeMenu === "Country" ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              {activeMenu === "Country" && (
                <ul className="">
                  {data.countries.map((country) => (
                    <li key={country}>
                      <label className="flex items-center pl-6 p-4 justify-between bg-indigo-950">
                        <span>{country}</span>
                        <input
                          type="radio"
                          name="country"
                          value={country}
                          checked={selectedCountry === country}
                          onChange={() => handleCountryChange(country)}
                          className="form-radio"
                        />
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            {selectedCountry && (
              <li className="">
                <div
                  onClick={() => toggleSubMenu("Block / License")}
                  className="block p-4 hover:bg-indigo-950 flex items-center justify-between cursor-pointer"
                >
                  <span className="flex items-center">
                    Block / License {selectedBlock && `(${selectedBlock})`}
                  </span>
                  <svg
                    className={`w-4 h-4 transition-transform transform ${
                      activeMenu === "Block / License" ? "rotate-180" : ""}
                    `}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
                {activeMenu === "Block / License" && (
                  <ul className="">
                    {data.blocks[selectedCountry]?.map((block) => (
                      <li key={block}>
                        <label className="flex items-center pl-6 p-4 justify-between bg-indigo-950">
                          <span>{block}</span>
                          <input
                            type="radio"
                            name="block"
                            value={block}
                            checked={selectedBlock === block}
                            onChange={() => handleBlockChange(block)}
                            className="form-radio"
                          />
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
            {selectedBlock && (
              <li className="">
                <div
                  onClick={() => toggleSubMenu("Field name")}
                  className="block p-4 hover:bg-indigo-950 flex items-center justify-between cursor-pointer"
                >
                  <span className="flex items-center">
                    Field name {selectedField && `(${selectedField})`}
                  </span>
                  <svg
                    className={`w-4 h-4 transition-transform transform ${
                      activeMenu === "Field name" ? "rotate-180" : ""}
                    `}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
                {activeMenu === "Field name" && (
                  <ul className="">
                    {data.fields[selectedBlock]?.map((field) => (
                      <li key={field}>
                        <label className="flex items-center pl-6 p-4 justify-between bg-indigo-950">
                          <span>{field}</span>
                          <input
                            type="radio"
                            name="field"
                            value={field}
                            checked={selectedField === field}
                            onChange={() => handleFieldChange(field)}
                            className="form-radio"
                          />
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
            {selectedField && (
              <li className="">
                <div
                  onClick={() => toggleSubMenu("Site")}
                  className="block p-4 hover:bg-indigo-950 flex items-center justify-between cursor-pointer"
                >
                  <span className="flex items-center">
                    Site {selectedSite && `(${selectedSite})`}
                  </span>
                  <svg
                    className={`w-4 h-4 transition-transform transform ${
                      activeMenu === "Site" ? "rotate-180" : ""}
                    `}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
                {activeMenu === "Site" && (
                  <ul className="">
                    {data.sites[selectedField]?.map((site) => (
                      <li key={site}>
                        <label className="flex items-center pl-6 p-4 justify-between bg-indigo-950">
                          <span>{site}</span>
                          <input
                            type="radio"
                            name="site"
                            value={site}
                            checked={selectedSite === site}
                            onChange={() => handleSiteChange(site)}
                            className="form-radio"
                          />
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
            {selectedSite && (
              <li className="">
                <div
                  onClick={() => toggleSubMenu("Well name")}
                  className="block p-4 hover:bg-indigo-950 flex items-center justify-between cursor-pointer"
                >
                  <span className="flex items-center">
                    Well name ({selectedWell.well_name})
                  </span>
                  <svg
                    className={`w-4 h-4 transition-transform transform ${
                      activeMenu === "Well name" ? "rotate-180" : ""}
                    `}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
                {activeMenu === "Well name" && (
                  <ul className="">
                    {data.wells[selectedSite]?.map((well) => (
                      <li key={well.well_id}>
                        <label className="flex items-center pl-6 p-4 justify-between bg-indigo-950">
                          <span>{well.well_name}</span>
                          <input
                            type="radio"
                            name="well"
                            value={well.well_name}
                            checked={selectedWell.well_id === well.well_id}
                            onChange={() => setSelectedWell(well)}
                            className="form-radio"
                          />
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </nav>
      )}
    </aside>
  );
}

export default Sidebar;
