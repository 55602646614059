import React, { useState, useEffect } from "react";
import { FaTrash, FaUndo } from "react-icons/fa";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useTrajectoryData } from "../../context/TrajectoryDataContext";
import AddNewTrajectoryEntrySlideOver from "./AddNewTrajectoryEntrySlideOver";

const TableHeader = () => (
  <tr>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      MD
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Inclination
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Azimuth
    </th>
  </tr>
);

const TrajectoryData = () => {
  const { data, updateData, handleAddNewEntry, handleDelete, handleRestore } = useTrajectoryData();

  const [isAddNewEntrySlideOverOpen, setIsAddNewEntrySlideOverOpen] = useState(false);
  const [localAcceptedValues, setLocalAcceptedValues] = useState([]);
  const [localDeletedValues, setLocalDeletedValues] = useState([]);

  useEffect(() => {
    // setLocalAcceptedValues(Object.keys(data.acceptedValues || {}));
    // setLocalDeletedValues(Object.keys(data.deletedValues || {}));


    setLocalAcceptedValues(
      Object.keys(data.acceptedValues|| {}).map((val) => parseFloat(val))
    );
    // setLocalSuggestedValues(
    //   Object.keys(data.suggestedValues).map((val) => parseFloat(val))
    // );
    setLocalDeletedValues(
      Object.keys(data.deletedValues||{}).map((val) => parseFloat(val)) 
    );


  }, [data]);

  const handleFieldClick = (fieldData, reference, displayValue) => {
    // Handle field click (this can be customized based on your requirements)
  };

  const renderTableRows = (values, isDeleted) => {
    if (!values) return null;
    return values.map((value) => {
      const fieldData = (data.acceptedValues?.[value]) || (data.deletedValues?.[value]);
      if (!fieldData) return null;

      return (
        <tr key={value}>
          <td className="px-4 py-2">
            {value}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              handleFieldClick(
                fieldData.inclination,
                "Inclination",
                fieldData.inclination?.display?.value
              )
            }
          >
            {fieldData.inclination?.display?.value || "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              handleFieldClick(
                fieldData.azimuth,
                "Azimuth",
                fieldData.azimuth?.display?.value
              )
            }
          >
            {fieldData.azimuth?.display?.value || "N/A"}
          </td>
          <td className="px-4 py-2 text-center flex flex-row justify-between items-center">
            <span className="flex-grow">
              {!isDeleted && (
                <button
                  onClick={(e) => { e.stopPropagation(); handleDelete(value); }}
                  className="text-red-500"
                >
                  <FaTrash />
                </button>
              )}
              {isDeleted && (
                <button
                  onClick={(e) => { e.stopPropagation(); handleRestore(value); }}
                  className="text-green-500"
                >
                  <FaUndo />
                </button>
              )}
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col justify-between">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-bold mb-4">Accepted Values</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(localAcceptedValues, false)}
          </tbody>
        </table>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center mt-2"
          onClick={() => setIsAddNewEntrySlideOverOpen(true)}
        >
          <PlusIcon className="w-4 h-4" />
        </button>
      </div>

      <div className="overflow-x-auto my-20">
        <h2 className="text-lg font-bold mb-4 text-gray-400">Deleted Values</h2>
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(localDeletedValues, true)}
          </tbody>
        </table>
      </div>

      <AddNewTrajectoryEntrySlideOver
        isOpen={isAddNewEntrySlideOverOpen}
        onClose={() => setIsAddNewEntrySlideOverOpen(false)}
        onSubmit={handleAddNewEntry}
        data={data}
      />
    </div>
  );
};

export default TrajectoryData;
