import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
const TrajectoryDataContext = createContext();

export const TrajectoryDataProvider = ({ children }) => {
  // const [data, setData] = useState({
  //   acceptedValues: {
  //     "0": {
  //       inclination: {
  //         options: {
  //           "0": {
  //             count: 3,
  //             reference: 190
  //           }
  //         },
  //         history: [],
  //         display: {
  //           value: "0",
  //           reference: 190,
  //           user_id: "userId"
  //         }
  //       },
  //       azimuth: {
  //         options: {
  //           "0": {
  //             count: 3,
  //             reference: 190
  //           }
  //         },
  //         history: [],
  //         display: {
  //           value: "0",
  //           reference: 190,
  //           user_id: "userId"
  //         }
  //       }
  //     },
  //     "30": {
  //       inclination: {
  //         options: {
  //           "1": {
  //             count: 1,
  //             reference: 191
  //           }
  //         },
  //         history: [],
  //         display: {
  //           value: "1",
  //           reference: 191,
  //           user_id: "userId"
  //         }
  //       },
  //       azimuth: {
  //         options: {
  //           "50": {
  //             count: 1,
  //             reference: 191
  //           }
  //         },
  //         history: [],
  //         display: {
  //           value: "50",
  //           reference: 191,
  //           user_id: "userId"
  //         }
  //       }
  //     }
  //   },
  //   deletedValues: {
  //     "150": {
  //       inclination: {
  //         options: {
  //           "0": {
  //             count: 3,
  //             reference: 190
  //           }
  //         },
  //         history: [],
  //         display: {
  //           value: "0",
  //           reference: 190,
  //           user_id: "userId"
  //         }
  //       },
  //       azimuth: {
  //         options: {
  //           "0": {
  //             count: 3,
  //             reference: 190
  //           }
  //         },
  //         history: [],
  //         display: {
  //           value: "0",
  //           reference: 190,
  //           user_id: "userId"
  //         }
  //       },
  //       message: {
  //         user_id: "userId",
  //         value: "Deleted by userId" // append user name using user id
  //       }
  //     }
  //   }
  // });

    const [data, setData] = useState({});

    
    const [wellId, setWellId] = useState("");


    const getWellIdFromQueryString = () => {
      const params = new URLSearchParams(window.location.search);
      return params.get('well_id');
    };
  
  
    useEffect(() => {
      const well_id = getWellIdFromQueryString();
      if (well_id) {
        setWellId(well_id);
      }
    }, [window.location.search]);
    
    useEffect(() => {
      if(wellId) {
        const fetchData = async () => {
          try {
            const response = await fetch(`/v1/well/get-trajectory-data/${wellId}`,{
              method: "GET",
              credentials: 'include',
            });
            if(response) {
              const data = await response.json();
              console.log("data:",data);
              if(data && data.data) {
                setData(data.data);
              }
            }
          } catch (err) {
            console.log("Err while fetching",err);
          }
        };
        fetchData();
      } 
    }, [wellId]);
  
  

  const updateData = async (newData) => {
    try {
      const response = await fetch(`/v1/well/update-trajectory-data`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          well_id: wellId,
          trajectory_data: newData
        }),
        credentials: 'include'
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data && data.data) {
          console.log("Trajectory data successfully update:",data.data);
          setData(newData);
        }
      } else {
        console.error('Failed to update well location data', response.statusText);
      }
    } catch (err) {
      console.error('Error updating well location data', err);
    }
  };

  const handleAddNewEntry = (formData) => {
    console.log("formData",formData);
    const newEntry = {
      [formData.MD]: {
        inclination: {
          options: {
            [formData.inclination]: {
              count: 1,
              reference: formData.inclination_ref,
            },
          },
          history: [],
          display: {
            value: formData.inclination,
            reference: formData.inclination_ref,
            user_id: "",
          },
        },
        azimuth: {
          options: {
            [formData.azimuth]: {
              count: 1,
              reference: formData.azimuth_ref,
            },
          },
          history: [],
          display: {
            value: formData.azimuth,
            reference: formData.azimuth_ref,
            user_id: "",
          },
        },
      },
    };

    console.log("New Entry:",newEntry)
    const updatedData = {
      ...data,
      acceptedValues: {
        ...data.acceptedValues,
        ...newEntry,
      },
    };

    updateData(updatedData);
  };

  const handleDelete = (value) => {
    const updatedAcceptedValues = { ...data.acceptedValues };
    const deletedValue = updatedAcceptedValues[value];
    delete updatedAcceptedValues[value];

    const updatedDeletedValues = {
      ...data.deletedValues,
      [value]: deletedValue,
    };

    updateData({
      ...data,
      acceptedValues: updatedAcceptedValues,
      deletedValues: updatedDeletedValues,
    });
  };

  const handleRestore = (value) => {
    const updatedDeletedValues = { ...data.deletedValues };
    const restoredValue = updatedDeletedValues[value];
    delete updatedDeletedValues[value];

    const updatedAcceptedValues = {
      ...data.acceptedValues,
      [value]: restoredValue,
    };

    updateData({
      ...data,
      acceptedValues: updatedAcceptedValues,
      deletedValues: updatedDeletedValues,
    });
  };

  const value = useMemo(() => ({
    data,
    updateData,
    handleAddNewEntry,
    handleDelete,
    handleRestore,
  }), [data]);

  return (
    <TrajectoryDataContext.Provider value={value}>
      {children}
    </TrajectoryDataContext.Provider>
  );
};

export const useTrajectoryData = () => {
  return useContext(TrajectoryDataContext);
};
