import React, { createContext, useState,useEffect, useContext, useMemo } from 'react';

const DataContext = createContext();

export const FormationDataProvider = ({ children }) => {
  // const [data, setData] = useState({
  //   acceptedValues: {
  //     'surficial deposits': {
  //       formation_formation_start_md: {
  //         options: {
  //           5.2: { count: 2, reference: "118" },
  //           12.7: { count: 2, reference: "118" },
  //           32: { count: 2, reference: "118" },
  //         },
  //         history: [],
  //         display: { value: 32, reference: "118", user_id: "id" }
  //       },
  //       formation_formation_start_tvd: {
  //         options: {
  //           10: { count: 1, reference: "120" },
  //           20: { count: 1, reference: "121" },
  //           30: { count: 1, reference: "122" }
  //         },
  //         history: [],
  //         display: { value: 30, reference: "122", user_id: "id" }
  //       },
  //       formation_formation_end_tvd: {
  //         options: {
  //           40: { count: 1, reference: "123" },
  //           50: { count: 1, reference: "124" },
  //           60: { count: 1, reference: "125" }
  //         },
  //         history: [],
  //         display: { value: 60, reference: "125", user_id: "id" }
  //       },
  //       formation_formation_end_md: {
  //         options: {
  //           70: { count: 1, reference: "126" },
  //           80: { count: 1, reference: "127" },
  //           90: { count: 1, reference: "128" }
  //         },
  //         history: [],
  //         display: { value: 90, reference: "128", user_id: "id" }
  //       },
  //       formation_description: {
  //         options: {
  //           'Tertiary formation': { count: 1, length: 18, reference: "20" },
  //           'Ground Level': { count: 1, length: 12, reference: "110" },
  //           'Ground Level ': { count: 1, length: 70, reference: "118" }
  //         },
  //         history: [],
  //         display: { value: 'Initial surficial deposits formation encountered at start of drilling.', reference: "110", user_id: "id" }
  //       }
  //     },
  //     'another formation': {
  //       formation_formation_start_md: {
  //         options: {
  //           100: { count: 2, reference: "200" },
  //           110: { count: 2, reference: "201" },
  //           120: { count: 2, reference: "202" },
  //         },
  //         history: [],
  //         display: { value: 120, reference: "202", user_id: "id" }
  //       },
  //       formation_formation_start_tvd: {
  //         options: {
  //           130: { count: 1, reference: "203" },
  //           140: { count: 1, reference: "204" },
  //           150: { count: 1, reference: "205" }
  //         },
  //         history: [],
  //         display: { value: 150, reference: "205", user_id: "id" }
  //       },
  //       formation_formation_end_tvd: {
  //         options: {
  //           160: { count: 1, reference: "206" },
  //           170: { count: 1, reference: "207" },
  //           180: { count: 1, reference: "208" }
  //         },
  //         history: [],
  //         display: { value: 180, reference: "208", user_id: "id" }
  //       },
  //       formation_formation_end_md: {
  //         options: {
  //           190: { count: 1, reference: "209" },
  //           200: { count: 1, reference: "210" },
  //           210: { count: 1, reference: "211" }
  //         },
  //         history: [],
  //         display: { value: 210, reference: "211", user_id: "id" }
  //       },
  //       formation_description: {
  //         options: {
  //           'Secondary formation': { count: 1, length: 20, reference: "30" },
  //           'Below Ground Level': { count: 1, length: 15, reference: "111" },
  //           'Formation encountered at depth of drilling.': { count: 1, length: 75, reference: "119" }
  //         },
  //         history: [],
  //         display: { value: 'Below Ground Level', reference: "111", user_id: "id" }
  //       }
  //     }
  //   },
  //   deletedValues: {}
  // });
  const [data, setData] = useState(null);
  const [wellId, setWellId] = useState("");

  const getWellIdFromQueryString = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('well_id');
  };


  useEffect(() => {
    const well_id = getWellIdFromQueryString();
    if (well_id) {
      setWellId(well_id);
    }
  }, [window.location.search]);

  useEffect(() => {
    if(wellId) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/v1/well/get-formation-data/${wellId}`,{
            method: "GET",
            credentials: 'include',
          });
          if(response) {
            const data = await response.json();
            console.log("data:",data);
            if(data && data.data) {
              setData(data.data);
            }
          }
        } catch (err) {
          console.log("Err while fetching",err);
        }
      };
      fetchData();
    } 
  }, [wellId]);



const updateData = async (newData) => {
  try {
    const response = await fetch(`/v1/well/update-formation-data`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        well_id: wellId,
        formation_data: newData
      }),
      credentials: 'include'
    });

    if (response.ok) {
      const data = await response.json();
      if (data && data.data) {
        console.log("Formation data successfully update:",data.data);
        setData(newData);
      }
    } else {
      console.error('Failed to update formation data', response.statusText);
    }
  } catch (err) {
    console.error('Error updating well location data', err);
  }
};

  // const updateData = (newData) => {
  //   setData(newData);
  // };

  const handleSaveFromImageViewer = (formation, field, newValue, newReference) => {
    setData((prevState) => {
      const formationData = prevState.acceptedValues[formation] || {};
      const fieldData = formationData[field] ? { ...formationData[field] } : { options: {}, history: [], display: {} };
      const isCustomValue = !fieldData.options[newValue];

      const isSameDisplayValueDifferentReference =
        fieldData.display.value === newValue &&
        fieldData.display.reference !== newReference;

      let newDisplay;

      if (isCustomValue || isSameDisplayValueDifferentReference) {
        newDisplay = { count: 1, reference: newReference };
      } else {
        newDisplay = fieldData.options[newValue];
        delete fieldData.options[newValue];
      }

      fieldData.history.push({
        value: fieldData.display.value,
        reference: fieldData.display.reference,
        user_id: fieldData.display.user_id,
      });

      if (!isSameDisplayValueDifferentReference) {
        fieldData.options[fieldData.display.value] = {
          count: 1,
          reference: fieldData.display.reference,
        };
      }

      fieldData.display = {
        value: newValue,
        reference: newDisplay.reference,
        user_id: fieldData.display.user_id,
      };

      return {
        ...prevState,
        acceptedValues: {
          ...prevState.acceptedValues,
          [formation]: {
            ...formationData,
            [field]: fieldData,
          },
        },
      };
    });
  };

  const handleDelete = (value) => {
    setData((prevState) => {
      const updatedFormationData = { ...prevState.acceptedValues };
      const deletedEntry = updatedFormationData[value];
      delete updatedFormationData[value];

      return {
        ...prevState,
        acceptedValues: updatedFormationData,
        deletedValues: {
          ...prevState.deletedValues,
          [value]: {
            ...deletedEntry,
            message: {
              user_id: "userId",
              value: `deleted by user`,
            },
          },
        },
      };
    });
  };

  const handleRestore = (value) => {
    setData((prevState) => {
      const restoredEntry = prevState.deletedValues[value];
      const updatedDeletedValues = { ...prevState.deletedValues };
      delete updatedDeletedValues[value];

      return {
        ...prevState,
        acceptedValues: {
          ...prevState.acceptedValues,
          [value]: restoredEntry,
        },
        deletedValues: updatedDeletedValues,
      };
    });
  };

  const value = useMemo(() => ({ data, updateData, handleSaveFromImageViewer, handleDelete, handleRestore }), [data]);

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the DataContext
export const useData = () => {
  return useContext(DataContext);
};
